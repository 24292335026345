<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- AUTOEVALUACION -->
      <v-card>
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon
            :color="evaluaciones.find(ev => ev.autoevaluacion == 1) ? 
              evaluaciones.find(ev => ev.autoevaluacion == 1).completada == 1 ?
                (evaluaciones.find(ev => ev.autoevaluacion == 1).finalizada == 1 ? 'primary' : 'success') : 'warning'
              : ''"
            left
          >
            fas fa-user-circle
          </v-icon>
          Auto evaluación
        </v-card-title>
        <v-card-text>
          <v-row class="pa-2">
            <v-col
              v-for="(item, index) in evaluaciones.filter(ev => ev.autoevaluacion == 1)"
              :key="index"
              cols="12" sm="6" md="4" xl="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : 'rowsel'"
                  :class="hover ? 'white--text' : ''"
                  @click="open_window(item.id)"
                >
                  <v-card-title
                    class="pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.nombre }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0 pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.apellido }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.anio }}
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- EVALUACIONES PENDIENTES -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left color="warning">fas fa-users</v-icon>
          Evaluaciones pendientes
        </v-card-title>
        <v-card-text>
          <v-row class="pa-2">
            <v-col
              v-for="(item, index) in evaluaciones.filter(ev => ev.autoevaluacion != 1 && ev.completada != 1)"
              :key="index"
              cols="12" sm="6" md="4" xl="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : 'rowwar'"
                  :class="hover ? 'white--text' : ''"
                  @click="open_window(item.id)"
                >
                  <v-card-title
                    class="pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.nombre }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0 pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.apellido }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.anio }}
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- EVALUACIONES completadaS -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left color="success">fas fa-users</v-icon>
          Evaluaciones completadas
        </v-card-title>
        <v-card-text>
          <v-row class="pa-2">
            <v-col
              v-for="(item, index) in evaluaciones.filter(ev => ev.autoevaluacion != 1 && ev.completada == 1 && ev.finalizada != 1)"
              :key="index"
              cols="12" sm="6" md="4" xl="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : 'cardSuccess'"
                  :class="hover ? 'white--text' : ''"
                  @click="open_window(item.id)"
                >
                  <v-card-title
                    class="pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.nombre }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0 pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.apellido }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.anio }}
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- EVALUACIONES FINALIZADAS -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left color="primary">fas fa-users</v-icon>
          Evaluaciones finalizadas
        </v-card-title>
        <v-card-text>
          <v-row class="pa-2">
            <v-col
              v-for="(item, index) in evaluaciones.filter(ev => ev.autoevaluacion != 1 && ev.finalizada == 1)"
              :key="index"
              cols="12" sm="6" md="4" xl="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : 'accent'"
                  :class="hover ? 'white--text' : ''"
                  @click="open_window(item.id)"
                >
                  <v-card-title
                    class="pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.nombre }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0 pb-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.apellido }}
                  </v-card-title>
                  <v-card-title
                    class="pt-0"
                    style="word-break: normal"
                    :style="hover ? 'font-size: 24px' : ''"
                  >
                    {{ item.anio }}
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      evaluaciones: []
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.get_evaluaciones()
    this.$store.state.loading = false
  },
  methods: {
    open_window (id) {
      const routeData = this.$router.resolve({ path: `/evaluacion/${id}` })
      window.open(routeData.href, '_blank')
    },
    async get_evaluaciones () {
      this.evaluaciones = []
      await this.$store.dispatch('evaluaciones/get_evaluaciones')
        .then((res) => {
          this.evaluaciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
    }
  }
}
</script>